import { ILeadSocialOrigin } from "./lead";

export type ChatIntegrationType =
  | "opaSuite"
  | "matrix"
  | "whatsappUnofficial"
  | "huggy"
  | "upChat"
  | "blip"
  | "multi360"
  | "brevo"
  | "szChat"
  | "myClients"
  | "mk";

export const chatIntegrationTypes: ChatIntegrationType[] = [
  "opaSuite",
  "matrix",
  "whatsappUnofficial",
  "huggy",
  "upChat",
  "blip",
  "multi360",
  "brevo",
  "myClients",
  "mk",
];

export type ChatIntegrationSocialMatching = {
  keywords: string;
  socialOrigin: ILeadSocialOrigin;
};

export type ChatIntegrationWhatsappUnofficialModel = {
  socialMatching: ChatIntegrationSocialMatching[]; // IChatIntegrationSocialMatching[]
};

export type ChatIntegrationSellerRelated = {
  seller: string;
  externalSeller: string;
};

export type TagSocialMatching = {
  tag: string[];
  socialOrigin: ILeadSocialOrigin;
};

export type ChatIntegrationOpaSuiteModel = {
  host: string;
  token: string;
  sellers: string[];
  sellersRelated: ChatIntegrationSellerRelated[];
  webhookVerified: boolean;
  webhookToken: string;
  onlyFromTags: string[];
  socialMatching: TagSocialMatching[];
};

export type ChatIntegrationMatrixModel = {
  host: string;
  sellers: string[];
  sellersRelated: ChatIntegrationSellerRelated[];
  login: string;
  key: string;
  account?: string;
};

export type ChatIntegrationHuggyModel = {
  token: string;
  refreshToken: string;
  tags: string[]; // leadsChannels
  clientId: string;
  clientSecret: string;
  departments: string[];
  sellers: string[];
  sellersRelated: ChatIntegrationSellerRelated[];
};

export type ChatIntegrationUpChatModel = {
  host: string;
  token: string;
  queues: number[]; // departmentId
  departments: string[]; // leadsChannels
};

export type ChatIntegrationMulti360Model = {
  email: string;
  password: string;
  sellers: string[];
};

export type ChatIntegrationMyClientsModel = {
  email: string;
  password: string;
  queues: string[];
};

export type ChatIntegrationBrevoModel = {
  token: string;
  sellers: string[];
};

export type ChatIntegrationSZChatModel = {
  host: string;
  departmentId: string;
  token: string;
  email: string;
  password: string;
  channels: string[];
  webhookVerified?: boolean;
  sellers: string[];
  sellersRelated: ChatIntegrationSellerRelated[];
};

export type ChatIntegration = {
  id: string;
  provider: string;
  name: string;
  active: boolean;
  type: ChatIntegrationType;
  whatsappUnofficial?: ChatIntegrationWhatsappUnofficialModel;
  opaSuite?: ChatIntegrationOpaSuiteModel;
  matrix?: ChatIntegrationMatrixModel;
  huggy?: ChatIntegrationHuggyModel;
  upChat?: ChatIntegrationUpChatModel;
  multi360?: ChatIntegrationMulti360Model;
  brevo?: ChatIntegrationBrevoModel;
  szChat?: ChatIntegrationSZChatModel;
  myClients?: ChatIntegrationMyClientsModel;
  createdAt: Date;
  updatedAt: Date;
};
