import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

import { ConfirmDialogProps } from "components/ConfirmDialog";
import { statusToText } from "models/seller";
import { queryClient } from "services/queryClient";
import { changeSellerStatus, getSellers } from "services/sellerService";
import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";

export const SELLER_CLIENTS_QUERY_KEY = "sellers";

export const useViewModel = () => {
  const navigate = useNavigate();
  const { selectedProvider, user } = useAuth();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, isError, refetch } = useQuery(
    [SELLER_CLIENTS_QUERY_KEY, currentPage, selectedProvider.id],
    () =>
      getSellers(
        currentPage,
        20,
        selectedProvider.id === "admin" ? undefined : selectedProvider.id
      ),
    { enabled: user !== undefined && user.role !== "seller" }
  );

  const [confirmDialogData, setConfirmDialogData] =
    useState<ConfirmDialogProps>();

  async function handleChangeStatus(index: number, newStatus: number) {
    setConfirmDialogData({
      show: true,
      title: "Alterar Status",
      message: `Deseja realmente alterar o status do vendedor para ${statusToText(
        newStatus,
        t
      )}?`,
      onConfirm: async () => {
        toast.info("Atualizando status do vendedor...");
        try {
          setConfirmDialogData(undefined);
          await changeSellerStatus(data!.data![index].id, newStatus);
          toast("Status atualizado com sucesso!", { type: "success" });
          queryClient.invalidateQueries([
            SELLER_CLIENTS_QUERY_KEY,
            currentPage,
          ]);
        } catch (error) {
          toast("Erro ao atualizar status do vendedor", { type: "error" });
        }
      },
      onCancel: () => {
        setConfirmDialogData(undefined);
      },
    });
  }

  return {
    t,
    sellers: data?.data,
    page: currentPage,
    nbPages: data?.pagination.nbPages,
    isLoading: isLoading,
    isError: isError,
    retry: refetch,
    confirmDialogData: confirmDialogData,
    setPage: setCurrentPage,
    handleChangeStatus: handleChangeStatus,
    navigate: navigate,
    user,
  };
};
