import { AxiosError } from "axios";
import { Button } from "components/Button";
import { Dialog } from "components/Dialog";
import { CopyText } from "components/Forms/CopyText";
import { Select } from "components/Forms/Select";
import { TextArea } from "components/Forms/TextArea";
import { TextField } from "components/Forms/TextField";
import useAuth from "hooks/useAuth";
import { BoardColumn } from "models/board";
import {
  ILeadSocialOrigin,
  LeadCard,
  socialOrigins,
  StoreLeadCard,
} from "models/lead";
import { Seller, SELLER_STATUS } from "models/seller";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateLeadData } from "services/leadService";
import { Log } from "utils/log";

type LeadForm = {
  name: string;
  socialOrigin: ILeadSocialOrigin;
  seller?: string;
  selledAt?: string;
  column: string;
  description: string;
};

type Props = {
  lead: StoreLeadCard;
  onSave: (lead: LeadCard, columnId: string) => Promise<void>;
  onClose: () => void;
  sellers: Seller[];
  columns: BoardColumn<LeadCard>[];
};

export const LeadDialogForm = ({
  lead,
  columns,
  onSave,
  onClose,
  sellers,
}: Props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<LeadForm>({
    defaultValues: {
      name: lead.name,
      socialOrigin: lead.socialOrigin,
      seller: lead.seller?.id ?? undefined,
      selledAt: lead.selledAt ? lead.selledAt.split("T")[0] : "",
      description: lead.description,
    },
  });

  const socialOrigin = watch("socialOrigin");
  const seller = watch("seller");
  const selectedColumn = watch("column");

  const isSelledColumn = useMemo(() => {
    const colum = columns.find((c) => c.id === selectedColumn);
    return colum?.tag?.value === "sold" || colum?.tag?.value === "scheduled";
  }, [selectedColumn]);

  const isInviability = useMemo(() => {
    const colum = columns.find((c) => c.id === selectedColumn);
    return colum?.tag?.value === "out_area";
  }, [selectedColumn]);

  const phone = useMemo(() => {
    if (lead.contact && lead.contact.startsWith("55")) {
      return lead.contact.slice(2);
    }
    return lead.contact ?? "";
  }, [lead.contact]);

  const sellerOptions = useMemo(() => {
    return sellers
      .filter((sellerItem) => {
        if (sellerItem.id === seller) return true;
        return sellerItem.status === SELLER_STATUS.Ativo;
      })
      .map((seller) => ({
        label: seller.name,
        value: seller.id,
      }));
  }, [sellers, seller]);

  useEffect(() => {
    register("socialOrigin", { required: true });
  }, [register]);

  const columnsItems = useMemo(() => {
    return columns.map((c) => ({ label: c.title, value: c.id }));
  }, [columns.length]);

  useEffect(() => {
    setValue("column", lead.status);
  }, [lead.status]);

  const onSubmit = async ({ column, ...data }: LeadForm) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const selledAt = data.selledAt === "" ? undefined : data.selledAt;
      const name = data.name.trim() === "" ? "Sem nome" : data.name.trim();

      const { seller } = await updateLeadData(lead.id, {
        name: name,
        socialOrigin: data.socialOrigin,
        seller: data.seller,
        selledAt: selledAt,
        description: data.description,
      });

      const _seller = sellers.find((s) => s.id === seller) ?? null;

      await onSave(
        {
          ...lead,
          ...data,
          status: column,
          seller: _seller,
          selledAt: selledAt,
          date: new Date(lead.date),
        },
        lead.status
      );
    } catch (error) {
      const _error = error as AxiosError;

      if (_error.isAxiosError) {
        const { status } = _error.response!;

        if (status === 403) {
          toast.error(
            t("leads.board.errors.already_attributed_seller", {
              name: lead.name,
            })
          );
          Log.error("Error on update lead", _error);
          return;
        }
      }

      toast.error(t("leads.board.errors.edit_lead", { name: lead.name }));
      Log.error("Error on update lead", error as Error);
    }

    setIsLoading(false);
  };

  return (
    <Dialog onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
        <TextField
          register={register("name", { required: true })}
          label={t("leads.board.lead_edit_form.name")}
          placeholder={t("leads.board.lead_edit_form.name_placeholder")}
          error={errors.name?.message}
          variant="secondary"
        />
        <CopyText
          text={phone}
          label={t("leads.board.lead_edit_form.phone")}
          variant="secondary"
        />
        <Select
          label={t("leads.board.lead_edit_form.column")}
          placeholder={t("leads.board.lead_edit_form.column_placeholder")}
          variant="secondary"
          selected={selectedColumn}
          options={columnsItems}
          onChange={(column) => setValue("column", column ?? lead.status)}
        />
        <Select
          onChange={(value) =>
            setValue("socialOrigin", String(value) as ILeadSocialOrigin)
          }
          selected={socialOrigin}
          options={socialOrigins(t)}
          label={t("leads.board.lead_edit_form.social_origin")}
          error={errors.socialOrigin?.message}
          variant="secondary"
          disabled={lead.campaign !== undefined}
        />
        {lead.campaign && (
          <CopyText
            label={t("leads.board.lead_edit_form.campaign")}
            text={
              lead.campaign?.url ??
              t("leads.board.lead_edit_form.campaign_fallback")
            }
            variant="secondary"
          />
        )}
        <TextField
          register={register("selledAt", {
            //Check if is a date
            validate: (value) => {
              if (!value) {
                if (isSelledColumn) {
                  return t("leads.board.lead_edit_form.sell_date_required");
                }
                return true;
              }
              const date = new Date(value);
              return date.toString() !== "Invalid Date";
            },
          })}
          placeholder={t("leads.board.lead_edit_form.sell_date_placeholder")}
          label={
            isSelledColumn
              ? t("leads.board.lead_edit_form.sell_date")
              : t("leads.board.lead_edit_form.sell_date_optional")
          }
          error={errors.selledAt?.message}
          type="date"
          variant="secondary"
        />
        <TextArea
          label={
            isInviability
              ? t("leads.form.description")
              : t("leads.form.description_optional")
          }
          placeholder={t("leads.form.description")}
          error={errors.description?.message}
          register={register("description", {
            required: {
              value: isInviability,
              message: t("leads.form.description_required"),
            },
          })}
          variant="secondary"
        />
        {user?.role !== "seller" ? (
          <Select
            onChange={(value) =>
              setValue("seller", String(value) as ILeadSocialOrigin)
            }
            selected={seller}
            options={sellerOptions}
            label={t("leads.board.lead_edit_form.seller")}
            placeholder={t("leads.board.lead_edit_form.seller_placeholder")}
            error={errors.seller?.message}
            variant="secondary"
            invertOptionsBox
          />
        ) : null}
        <div className="flex items-center justify-end gap-2 mt-4">
          <Button theme="cancel" type="button" onClick={onClose}>
            {t("actions.cancel")}
          </Button>
          <Button theme="highlight" className="px-5" loading={isLoading}>
            {t("actions.edit")}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
