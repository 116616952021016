import { useQuery } from "react-query";

import {
  getDashboardLeadsData,
  getDashboardInsideAreaData,
  getDashboardSellsData,
  getDashboardFinancialResumeData,
} from "services/dashboardService";
import useAuth from "hooks/useAuth";
import { useFilters } from "hooks/useFilters";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const KEY = "dashboard";

type TAB = "leads" | "sales";

export const useViewmodel = () => {
  const { user, selectedProvider, selectedSeller } = useAuth();
  const { selectedDate } = useFilters();
  const { t } = useTranslation();

  const [tab, setTab] = useState<TAB>("leads");

  const range = `${selectedDate?.initDate.toDateString()}-${selectedDate?.endDate.toDateString()}`;

  const leadsData = useQuery(
    [KEY, "leads", user?._id, range, selectedProvider.id, selectedSeller],
    () =>
      getDashboardLeadsData(
        selectedDate!.initDate,
        selectedDate!.endDate,
        selectedSeller
      ),
    { enabled: selectedProvider.id !== "admin" }
  );
  leadsData.isLoading =
    leadsData.isLoading || (leadsData.isFetching && !leadsData.data);

  const insideAreaData = useQuery(
    [KEY, "inside-area", user?._id, range, selectedProvider.id, selectedSeller],
    () =>
      getDashboardInsideAreaData(
        selectedDate!.initDate,
        selectedDate!.endDate,
        selectedSeller
      ),
    { enabled: selectedProvider.id !== "admin" }
  );
  insideAreaData.isLoading =
    insideAreaData.isLoading ||
    (insideAreaData.isFetching && !insideAreaData.data);

  const sellsData = useQuery(
    [KEY, "sells", user?._id, range, selectedProvider.id, selectedSeller],
    () =>
      getDashboardSellsData(
        selectedDate!.initDate,
        selectedDate!.endDate,
        selectedSeller
      ),
    { enabled: selectedProvider.id !== "admin" }
  );
  sellsData.isLoading =
    sellsData.isLoading || (sellsData.isFetching && !sellsData.data);

  const financialResumeData = useQuery(
    [
      KEY,
      "financial-resume",
      user?._id,
      range,
      selectedProvider.id,
      selectedSeller,
    ],
    () =>
      getDashboardFinancialResumeData(
        selectedDate!.initDate,
        selectedDate!.endDate,
        selectedSeller
      ),
    { enabled: selectedProvider.id !== "admin" }
  );
  financialResumeData.isLoading =
    financialResumeData.isLoading ||
    (financialResumeData.isFetching && !financialResumeData.data);

  const refetch = () => {
    leadsData.refetch();
    insideAreaData.refetch();
    sellsData.refetch();
    financialResumeData.refetch();
  };

  return {
    sellsData: sellsData.data,
    leadsData: leadsData.data,
    insideAreaData: insideAreaData.data,
    financialResumeData: financialResumeData.data,
    isLoading:
      leadsData.isLoading ||
      insideAreaData.isLoading ||
      sellsData.isLoading ||
      financialResumeData.isLoading,
    isError:
      leadsData.isError ||
      insideAreaData.isError ||
      sellsData.isError ||
      financialResumeData.isError,
    refetch,
    hasProvider: selectedProvider.id !== "admin",
    tab,
    setTab,
    hasData: leadsData.data && insideAreaData.data && sellsData.data,
    isAllSellers: selectedSeller === "all",
    t,
  };
};
