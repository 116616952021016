import { Pagination } from "components/Tables/Pagination";
import { Table } from "components/Tables/Table";
import { ConfirmDialog } from "components/ConfirmDialog";

import { useViewModel } from "./viewModel";
import { Seller, SELLER_STATUS, statusToText } from "models/seller";
import { TableField } from "components/Tables/Table/components/TableBody";
import { PageHeader } from "components/Page/PageHeader";
import { PageFilter } from "components/Page/PageFilter";
import { Button } from "components/Button";
import { Link } from "react-router-dom";

export default function Sellers() {
  const {
    navigate,
    sellers,
    page,
    nbPages,
    confirmDialogData,
    handleChangeStatus,
    setPage,
    isLoading,
    isError,
    retry,
    user,
    t,
  } = useViewModel();

  const headers =
    user?.role === "admin"
      ? t("sellers.table.admin_header", { returnObjects: true })
      : t("sellers.table.provider_header", { returnObjects: true });

  const fields: TableField<Seller>[] =
    user?.role === "admin"
      ? [
          { key: "name" },
          { key: "email" },
          { key: "providerName" },
          { key: "status", type: "status" },
        ]
      : [{ key: "name" }, { key: "email" }, { key: "status", type: "status" }];

  return (
    <div>
      {confirmDialogData && <ConfirmDialog {...confirmDialogData} />}
      <div className="flex justify-between items-center">
        <PageHeader title={t("sellers.title")} />
        <div className="flex items-center gap-3">
          <PageFilter hideTimeFilter />
          <Link to="/vendedores/novo">
            <Button leftIcon="/icons/form/add.svg">{t("actions.add")}</Button>
          </Link>
        </div>
      </div>
      <Table
        className="mt-7"
        headers={headers}
        fields={fields}
        data={sellers}
        transforms={[
          {
            key: "status",
            transform: (seller) => statusToText(seller.status, t),
          },
        ]}
        isLoading={isLoading}
        hasError={isError}
        onRetry={retry}
        actions={(seller, index) => {
          return (
            <>
              <button onClick={() => navigate(`/vendedores/${seller.id}`)}>
                {t("actions.view")}
              </button>
              <button
                onClick={() => navigate(`/vendedores/editar/${seller.id}`)}
              >
                {t("actions.edit")}
              </button>
              {seller.status !== SELLER_STATUS.Ativo && (
                <button
                  onClick={() => handleChangeStatus(index, SELLER_STATUS.Ativo)}
                >
                  {t("actions.activate")}
                </button>
              )}
              {seller.status !== SELLER_STATUS.Inativo && (
                <button
                  onClick={() =>
                    handleChangeStatus(index, SELLER_STATUS.Inativo)
                  }
                >
                  {t("actions.inactivate")}
                </button>
              )}
            </>
          );
        }}
      />
      <Pagination
        currentPage={page}
        handleChangePage={setPage}
        numberOfPages={nbPages ?? 0}
      />
    </div>
  );
}
