import Leads from "pages/leads";
import { Dashboard } from "./Dashboard";
import { useViewmodel } from "./viewModel";
import { PageHeader } from "components/Page/PageHeader";
import { PageFilter } from "components/Page/PageFilter";

export default function Home() {
  const { user, t } = useViewmodel();

  if (user?.role === "seller" && user.isActive) {
    return <Leads />;
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <PageHeader title={t("dashboard.title", { user: user?.name })} />
        <PageFilter />
      </div>
      {user?.isActive ? (
        <Dashboard />
      ) : (
        <div>
          <p>
            Sua conta foi desativada. Para mais informações entre em contato com
            o suporte
          </p>
        </div>
      )}
    </div>
  );
}
