import ProtectedRoute from "layouts/ProtectedRoute";
import PublicRoute from "layouts/PublicRoute";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./home";
import Login from "./login";

import Clients from "./clients";
import { ShowClient } from "./clients/show";
import { NewClient } from "./clients/new";
import { EditClient } from "./clients/edit";

import Sellers from "./sellers";
import { ShowSeller } from "./sellers/show";
import { NewSeller } from "./sellers/new";
import { EditSeller } from "./sellers/edit";

import Campaigns from "./campaigns"
import Campaign from "./campaign";
import Leads from "./leads";
import Reports from "./reports"

import { Page404 } from "./page404";
import { WhatsappConnect } from './wpp-connect'
import { ReturnHuggy } from "./return/huggy";
import ConfirmRecover from "./confirm-recover";
import RecoverPassword from "./recover-password";
import { CreateLeadForm } from "./leads/new";
import { ExternalDashboard } from "./open-dashboard/Dashboard";
import { PrivacyPolicy } from "./legal/privacy-policy";
import { TermsOfUse } from "./legal/terms-of-use";
import { DataDeletion } from "./legal/data-deletion";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute element={<Home/>} />} />
        <Route path="/404" element={<ProtectedRoute element={<Page404/>} />} />
        <Route path="/conectar" element={<PublicRoute element={<WhatsappConnect />} />} />

        <Route path="/return-huggy-:providerId" element={<ProtectedRoute element={<ReturnHuggy/>} />} />

        <Route path="/recuperar-senha" element={<PublicRoute element={<ConfirmRecover />} />} />
        <Route path="/recuperar" element={<PublicRoute element={<RecoverPassword />} />} />

        <Route path="/clientes" element={<ProtectedRoute element={<Clients/>} />} />
        <Route path="/clientes/novo" element={<ProtectedRoute element={<NewClient/>} />} />
        <Route path="/clientes/editar/:id" element={<ProtectedRoute element={<EditClient/>} />} />
        <Route path="/clientes/:id" element={<ProtectedRoute element={<ShowClient/>} />} />

        <Route path="/vendedores" element={<ProtectedRoute element={<Sellers/>} />} />
        <Route path="/vendedores/novo" element={<ProtectedRoute element={<NewSeller/>} />} />
        <Route path="/vendedores/editar/:id" element={<ProtectedRoute element={<EditSeller/>} />} />
        <Route path="/vendedores/:id" element={<ProtectedRoute element={<ShowSeller/>} />} />

        <Route path="/campanhas" element={<ProtectedRoute element={<Campaigns/>} />} />
        <Route path="/campanhas/:id" element={<ProtectedRoute element={<Campaign/>} />} />

        <Route path="/leads" element={<ProtectedRoute element={<Leads/>} />} />
        <Route path="/leads/novo" element={<ProtectedRoute element={<CreateLeadForm/>} />} />

        <Route path="/relatorios" element={<ProtectedRoute element={<Reports/>} />} />

        <Route path="/login" element={<PublicRoute element={<Login/>} />} />

        <Route path="/open-dashboard-:providerId" element={<ExternalDashboard/>} />

        <Route path="/politica-de-privacidade" element={<PublicRoute element={<PrivacyPolicy/>} />} />
        <Route path="/termos-de-uso" element={<PublicRoute element={<TermsOfUse/>} />} />
        <Route path="/exclusao-de-dados" element={<PublicRoute element={<DataDeletion/>} />} />

        <Route path="*" element={<ProtectedRoute element={<Page404/>} />} />
      </Routes>
    </Router>
  );
}

export default App;
