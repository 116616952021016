import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import {
  ChatIntegration,
  ChatIntegrationSellerRelated,
  ChatIntegrationType,
  TagSocialMatching,
} from "models/chatIntegration";
import { AxiosError } from "axios";
import { messageToError, ValidationError } from "utils/validation";
import { toast } from "react-toastify";
import {
  getIntegrationSellers,
  getIntegrationTags,
  webhookCheck,
} from "services/chatIntegrationService";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { queryClient } from "services/queryClient";
import { IntegrationSeller } from "models/integrationSeller";
import { Seller } from "models/seller";
import { getSellers } from "services/sellerService";
import { useTranslation } from "react-i18next";
import { ILeadSocialOrigin } from "models/lead";

export type OpaIntegrationForm = {
  name: string;
  type: ChatIntegrationType;
  opaSuite: {
    host: string;
    token: string;
    sellers: string[];
    sellersRelated: ChatIntegrationSellerRelated[];
    onlyFromTags: string[];
    socialMatching: TagSocialMatching[];
  };
};

export const useViewModel = (
  provider: string,
  selectedIntegration: ChatIntegration | undefined,
  handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction
) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    setError,
    watch,
  } = useForm<OpaIntegrationForm>({
    defaultValues: {
      opaSuite: {
        onlyFromTags: [],
        socialMatching: [],
      },
    },
  });

  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [loadingForm, setLoadingForm] = useState(false);
  const [sellers, setSelers] = useState<IntegrationSeller[]>([]);
  const [internalSellers, setInternalSellers] = useState<Seller[]>([]);
  const [tags, setTags] = useState<{ id: string; name: string }[]>([]);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const selectedSellers = watch("opaSuite.sellers") ?? [];
  const selectedSellersRelated = watch("opaSuite.sellersRelated") ?? [];
  const selectedOnlyFromTags = watch("opaSuite.onlyFromTags", []) 
  const selectedSocialMatching = watch("opaSuite.socialMatching", []) 

  const selectedSellersOptions = useMemo(() => {
    return selectedSellers.map(
      (seller) => sellers.find((_seller) => _seller.id === seller)!
    );
  }, [sellers, selectedSellers]);

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" },
    });
  }, [register]);

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "");
    setValue("type", selectedIntegration?.type ?? "opaSuite");
    setValue("opaSuite.host", selectedIntegration?.opaSuite?.host ?? "");
    setValue("opaSuite.sellers", selectedIntegration?.opaSuite?.sellers ?? []);
    setValue(
      "opaSuite.sellersRelated",
      selectedIntegration?.opaSuite?.sellersRelated ?? []
    );
    setValue("opaSuite.token", selectedIntegration?.opaSuite?.token ?? "");
    setValue("opaSuite.onlyFromTags", selectedIntegration?.opaSuite?.onlyFromTags ?? []);
    setValue("opaSuite.socialMatching", selectedIntegration?.opaSuite?.socialMatching ?? []);
  }, [selectedIntegration, setValue]);

  async function handleSubmitFormFirstStep(data: OpaIntegrationForm) {
    if (loadingForm) return;
    setLoadingForm(true);

    try {
      toast.info("Buscando os vendedores...");
      const { attendents } = await getIntegrationSellers({
        type: data.type as ChatIntegrationType,
        opaSuite: {
          host: data.opaSuite.host,
          token: data.opaSuite.token,
        },
      });
      const { data: tags } = await getIntegrationTags({
        type: data.type as ChatIntegrationType,
        opaSuite: {
          host: data.opaSuite.host,
          token: data.opaSuite.token,
        },
      });
      setSelers(attendents);
      setTags(tags);
      setLoadingForm(false);
      setStep(1);
      toast.success("Vendedores buscados com sucesso!");
    } catch (error) {
      toast.error(errorMessage(error as Error));
      setLoadingForm(false);
    }
  }

  const errorMessage = (error: Error) => {
    const _err = error as AxiosError;
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as {
        errors: ValidationError[] | undefined;
      };

      if (data.errors) {
        const fields = data.errors.map((error) => {
          setError(error.key as keyof OpaIntegrationForm, {
            message: messageToError(error.message),
          });
          return error.key;
        });
        return `Os campos ${fields.join(", ")} são inválidos`;
      }
      if (_err.response.status === 400)
        return "O domínio ou o token são inválidos";
    }
    return "Ocorreu um erro, por favor tente novamente";
  };

  async function handleSubmitFormSecondStep() {
    if (loadingForm) return;
    setLoadingForm(true);

    const internalSellers = await getSellers(1, 100, provider);
    setInternalSellers(internalSellers.data);

    setLoadingForm(false);

    setStep(2);
  }

  async function handleSubmitFormThirdStep(data: OpaIntegrationForm) {
    if (loadingForm) return;
    setLoadingForm(true);
    await handleSaveOrUpdate({
      ...data,
      opaSuite: {
        ...data.opaSuite,
        sellersRelated: data.opaSuite.sellersRelated.filter((sellerRelated) =>
          data.opaSuite.sellers.includes(sellerRelated.externalSeller)
        ),
      },
    });
    setLoadingForm(false);
  }

  const handleCancelWebhookStep = () => {
    setStep(0);
  };

  const handleShowWebhookStep = () => {
    setStep(3);
  };

  const handleVerifyWebhook = async () => {
    if (!selectedIntegration) return;

    setVerifyLoading(true);

    let refetch = false;

    try {
      const isVerified = await webhookCheck(selectedIntegration.id);
      selectedIntegration.opaSuite!.webhookVerified = isVerified;
      if (isVerified) {
        toast.success("Webhook verificado com sucesso!");
        refetch = true;
      } else {
        toast.warn("O webhook não está verificado");
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao verificar o webhook");
    }

    setVerifyLoading(false);

    if (refetch) {
      await queryClient.invalidateQueries("chatIntegrations");
    }
  };

  const handleAppendSocialMatching = () => {
    const socialMatching = getValues("opaSuite.socialMatching");
    setValue("opaSuite.socialMatching", [...socialMatching, {
      tag: [],
      socialOrigin: "facebook",
    }]);
  };

  const handleUpdateSocialMatchingTags = (index: number) => (tags: string[]) => {
    const socialMatching = getValues("opaSuite.socialMatching");
    setValue("opaSuite.socialMatching", socialMatching.map((social, indexSocial) => indexSocial === index ? { ...social, tag: tags } : social));
  };

  const handleUpdateSocialMatchingOrigin = (index: number) => (socialOrigin: string | undefined) => {
    const socialMatching = getValues("opaSuite.socialMatching");
    setValue("opaSuite.socialMatching", socialMatching.map((social, indexSocial) => indexSocial === index ? { ...social, socialOrigin: socialOrigin as ILeadSocialOrigin } : social));
  };

  const handleRemoveSocialMatching = (index: number) => {
    const socialMatching = getValues("opaSuite.socialMatching");
    setValue("opaSuite.socialMatching", socialMatching.filter((_, indexSocial) => indexSocial !== index));
  };

  return {
    t,
    selectedIntegration,
    selectedSellersRelated,
    internalSellers,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    handleSubmitFormThirdStep,
    step,
    sellers,
    selectedSellers,
    selectedSellersOptions,
    verifyLoading,
    handleShowWebhookStep,
    handleVerifyWebhook,
    handleCancelWebhookStep,
    tags,
    selectedOnlyFromTags,
    selectedSocialMatching,
    handleAppendSocialMatching,
    handleUpdateSocialMatchingTags,
    handleUpdateSocialMatchingOrigin,
    handleRemoveSocialMatching,
  };
};
