type Roles = "admin" | "provider" | "seller";

export type Route = {
  path: string;
  name: "dashboard" | "clients" | "sellers" | "campaigns" | "reports" | "leads";
  icon: string;
};

export const getRoutes = (
  role: Roles | undefined,
  active: boolean | undefined
): Route[] => {
  if (!role) {
    return [];
  }

  if (!active) {
    return [
      {
        path: "/",
        name: "dashboard",
        icon: "dashboard",
      },
    ];
  }

  if (role === "admin") {
    return [
      {
        path: "/",
        name: "dashboard",
        icon: "dashboard",
      },
      {
        path: "/clientes",
        name: "clients",
        icon: "clients",
      },
      {
        path: "/vendedores",
        name: "sellers",
        icon: "sellers",
      },
      {
        path: "/campanhas",
        name: "campaigns",
        icon: "campaigns",
      },
      {
        path: "/relatorios",
        name: "reports",
        icon: "dashboard",
      },
      {
        path: "/leads",
        name: "leads",
        icon: "leads",
      },
    ];
  }

  if (role === "seller") {
    return [
      {
        path: "/",
        name: "leads",
        icon: "leads",
      },
    ];
  }

  return [
    {
      path: "/",
      name: "dashboard",
      icon: "dashboard",
    },
    {
      path: "/vendedores",
      name: "sellers",
      icon: "sellers",
    },
    {
      path: "/campanhas",
      name: "campaigns",
      icon: "campaigns",
    },
    {
      path: "/relatorios",
      name: "reports",
      icon: "dashboard",
    },
    {
      path: "/leads",
      name: "leads",
      icon: "leads",
    },
  ];
};
