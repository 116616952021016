import { Provider } from "models/provider";

import { Button } from "components/Button";
import { FormColumn, FormRow, Form } from "components/Forms/Form";
import { MaskedTextField } from "components/Forms/MaskedTextField";
import { TextField } from "components/Forms/TextField";

import { Masks } from "utils/mask";

import { FormFields, useViewModel } from "./viewModel";
import { StatusSelect } from "components/Forms/StatusSelect";
import { MoneyTextField } from "components/Forms/MoneyTextField";
import { Select } from "components/Forms/Select";

type ProviderFormProps = {
  provider?: Provider;
  onSubmit?: (fields: FormFields) => Promise<void>;
  enabled: boolean;
  action?: string;
};

export function ProviderForm({
  provider,
  enabled,
  action,
  onSubmit,
}: ProviderFormProps) {
  const {
    handleSubmit,
    errors,
    register,
    whatsapp,
    date,
    setValue,
    loading,
    status,
    averageTicket,
    currency,
    t,
  } = useViewModel(provider, onSubmit);

  return (
    <Form onSubmit={handleSubmit} className="mt-12 tablet:mt-24">
      <FormRow>
        <FormColumn>
          <TextField
            label={t("providers.form.provider_name")}
            placeholder={t("providers.form.provider_name_placeholder")}
            error={errors.name?.message}
            type="text"
            disabled={!enabled}
            register={register("name", {
              required: {
                value: true,
                message: t("providers.form.validation.provider_name_required"),
              },
            })}
          />
          <TextField
            label={t("providers.form.client_name")}
            placeholder={t("providers.form.client_name_placeholder")}
            error={errors.client?.message}
            disabled={!enabled}
            register={register("client", {
              required: {
                value: true,
                message: t("providers.form.validation.client_name_required"),
              },
            })}
          />
          <MaskedTextField
            label={t("providers.form.client_whatsapp")}
            placeholder={t("providers.form.client_whatsapp_placeholder")}
            type="phone"
            error={errors.whatsapp?.message}
            mask={Masks.PHONE}
            value={whatsapp}
            disabled={!enabled}
            onChange={(value) => {
              setValue("whatsapp", value);
            }}
          />
          <Select
            label={t("providers.form.provider_currency")}
            placeholder={t("providers.form.provider_currency_placeholder")}
            selected={currency}
            options={t("currency", { returnObjects: true })}
            onChange={(currency) => setValue("currency", currency!)}
            invertOptionsBox
          />
          <TextField
            label={t("providers.form.client_email")}
            type="email"
            placeholder={t("providers.form.client_email_placeholder")}
            error={errors.email?.message}
            disabled={!enabled}
            register={register("email", {
              required: {
                value: true,
                message: t("providers.form.validation.client_email_required"),
              },
              pattern: {
                value: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: t("providers.form.validation.client_email_invalid"),
              },
            })}
          />
        </FormColumn>
        <FormColumn>
          {provider === undefined && (
            <TextField
              label={t("providers.form.client_password")}
              type="password"
              placeholder={t("providers.form.client_password_placeholder")}
              error={errors.password?.message}
              disabled={!enabled}
              register={register("password", {
                required: {
                  value: true,
                  message: t(
                    "providers.form.validation.client_password_required"
                  ),
                },
                minLength: {
                  value: 6,
                  message: t(
                    "providers.form.validation.client_password_invalid"
                  ),
                },
              })}
            />
          )}
          <MaskedTextField
            label={t("providers.form.register_date")}
            placeholder={t("providers.form.register_date_placeholder")}
            type="text"
            disabled={!enabled}
            error={errors.date?.message}
            mask={Masks.DATE}
            value={date}
            onChange={(value) => {
              setValue("date", value);
            }}
          />
          <TextField
            label={t("providers.form.responsible")}
            placeholder={t("providers.form.responsible_placeholder")}
            error={errors.responsible?.message}
            disabled={!enabled}
            register={register("responsible", {
              required: {
                value: true,
                message: t("providers.form.validation.responsible_required"),
              },
            })}
          />
          <StatusSelect
            label={t("providers.form.status")}
            selected={String(status)}
            disabled={!enabled}
            options={Object.values(
              t("providers.status", { returnObjects: true })
            ).map((status, index) => ({
              label: status,
              value: index.toString(),
            }))}
            onChange={(value) => setValue("status", Number(value))}
          />
          <MoneyTextField
            label={t("providers.form.average_ticket")}
            placeholder={t("providers.form.average_ticket_placeholder")}
            type="text"
            disabled={!enabled}
            error={errors.averageTicket?.message}
            value={averageTicket}
            onChange={(value) => {
              setValue("averageTicket", value);
            }}
          />
        </FormColumn>
      </FormRow>
      {action && (
        <Button type="submit" loading={loading} className="w-48 mt-20">
          {action}
        </Button>
      )}
    </Form>
  );
}
