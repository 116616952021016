import { Button } from "components/Button";
import { Form, FormColumn, FormRow } from "components/Forms/Form";
import { Select } from "components/Forms/Select";
import { TextField } from "components/Forms/TextField";
import { useViewModel } from "./viewModel";
import { MaskedTextField } from "components/Forms/MaskedTextField";
import { Masks } from "utils/mask";
import { socialOrigins } from "models/lead";
import { PageHeader } from "components/Page/PageHeader";
import { PageFilter } from "components/Page/PageFilter";
import { TextArea } from "components/Forms/TextArea";
import { Checkbox } from "components/Forms/Checkbox";

export function CreateLeadForm() {
  const {
    handleSubmit,
    errors,
    register,
    loading,
    handleChangeSocialOrigin,
    user,
    columnId,
    handleChangeColumnId,
    phone,
    handleChangePhone,
    isLoadingProviderColumns,
    providerColumns,
    socialOrigin,
    handleChangeSeller,
    seller,
    sellerOptions,
    selectedProvider,
    isSelledColumn,
    isInviability,
    t,
    allowDuplicated,
    handleChangeAllowDuplicated,
  } = useViewModel();

  if (selectedProvider.id === "admin") {
    return (
      <section>
        <div className="flex justify-between items-center">
          <PageHeader title={t("leads.form.title")} backPath="/leads" />
          <PageFilter />
        </div>
        <div className="w-full flex flex-col gap-6 items-center justify-center text-center h-[calc(75svh)]">
          <p className="text-white text-xl text-center">
            {t("leads.form.select_provider")}
          </p>
        </div>
      </section>
    );
  }

  return (
    <section>
      <div className="flex justify-between items-center">
        <PageHeader title={t("leads.form.title")} backPath="/leads" />
        <PageFilter />
      </div>
      <Form onSubmit={handleSubmit} className="mt-12 tablet:mt-24">
        <FormRow>
          <FormColumn>
            <TextField
              label={t("leads.form.name")}
              placeholder={t("leads.form.name_placeholder")}
              error={errors.name?.message}
              register={register("name", {
                required: {
                  value: true,
                  message: t("leads.form.name_required"),
                },
              })}
            />
            <TextField
              label={t("leads.form.entrance_date")}
              placeholder={t("leads.form.entrance_date_placeholder")}
              error={errors.date?.message}
              type="date"
              register={register("date", {
                required: {
                  value: true,
                  message: t("leads.form.entrance_date_required"),
                },
              })}
            />
            <MaskedTextField
              label={t("leads.form.phone")}
              placeholder={t("leads.form.phone_placeholder")}
              error={errors.phone?.message}
              mask={[Masks.OLD_PHONE, Masks.PHONE]}
              value={phone}
              onChange={handleChangePhone}
            />
            <Select
              onChange={handleChangeSocialOrigin}
              selected={socialOrigin}
              options={socialOrigins(t)}
              label={t("leads.form.lead_origin")}
              placeholder={t("leads.form.lead_origin_placeholder")}
              error={errors.socialOrigin?.message}
            />
            <Select
              label={t("leads.form.lead_column")}
              placeholder={t("leads.form.lead_column_placeholder")}
              selected={columnId}
              options={providerColumns ?? []}
              onChange={handleChangeColumnId}
              isLoading={isLoadingProviderColumns}
              error={errors.columnId?.message}
            />
            {user && user?.role !== "seller" ? (
              <Select
                label={t("leads.form.seller")}
                options={sellerOptions ?? []}
                onChange={handleChangeSeller}
                selected={seller}
                placeholder={t("leads.form.seller_placeholder")}
                isLoading={sellerOptions === undefined}
              />
            ) : null}
            <TextField
              register={register("selledAt", {
                //Check if is a date
                validate: (value) => {
                  if (!value) {
                    if (isSelledColumn) {
                      return t("leads.form.selled_at_required");
                    }
                    return true;
                  }
                  const date = new Date(value);
                  return date.toString() !== "Invalid Date";
                },
              })}
              placeholder={t("leads.form.selled_at_placeholder")}
              label={
                isSelledColumn
                  ? t("leads.form.selled_at")
                  : t("leads.form.selled_at_optional")
              }
              error={errors.selledAt?.message}
              type="date"
            />
            <TextArea
              label={
                isInviability
                  ? t("leads.form.description")
                  : t("leads.form.description_optional")
              }
              placeholder={t("leads.form.description")}
              error={errors.description?.message}
              register={register("description", {
                required: {
                  value: isInviability,
                  message: t("leads.form.description_required"),
                },
              })}
            />
            <Checkbox
              label={t("leads.form.allow_duplicated")}
              isChecked={allowDuplicated}
              onClick={handleChangeAllowDuplicated}
            />
          </FormColumn>
        </FormRow>
        <Button
          type="submit"
          size="default"
          loading={loading}
          className="w-48 mt-20"
        >
          {t("actions.add")}
        </Button>
      </Form>
    </section>
  );
}
