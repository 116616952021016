import { cn } from "utils/styles";
import { ConvertionCard } from "./ConvertionCard";
import { SalesCard } from "./SalesCard";
import { SalesCostCard } from "./SalesCostCard";
import { FinancialResumeData, SellsData } from "services/dashboardService";
import { MRRCard } from "./MrrCard";
import { RoiCard } from "./RoiCard";

type Props = {
  variant?: "primary" | "secondary";
  sellsData: SellsData;
  financialResumeData: FinancialResumeData;
};

export const SalesResume = ({
  variant = "primary",
  sellsData,
  financialResumeData,
}: Props) => {
  return (
    <div
      className={cn(
        "flex flex-col desktop:flex-row gap-3 desktop:gap-1.5 rounded-2xl",
        variant === "primary" ? "bg-primary/40 p-4" : "flex-1"
      )}
    >
      <div className="flex flex-col laptop:flex-row gap-3 desktop:gap-1.5 desktop-xl:flex-1">
        <SalesCard
          variant={variant}
          salles={sellsData.soldGrouped.leads}
          oldSalles={sellsData.oldSoldGrouped.leads}
        />
        <ConvertionCard
          variant={variant}
          convertion={sellsData.soldGrouped.leadsPercentage}
        />
        <SalesCostCard
          variant={variant}
          sellCost={sellsData.soldGrouped.leadsCost}
        />
      </div>
      <div className="flex gap-3 desktop:gap-1.5 flex-1 flex-col laptop:flex-row">
        <MRRCard
          variant={variant}
          mrrPeriod={financialResumeData.period.mrr}
          mrrHistoric={financialResumeData.historic.mrr}
        />
        <RoiCard
          variant={variant}
          roiPeriod={financialResumeData.period.roi}
          roiHistoric={financialResumeData.historic.roi}
        />
      </div>
    </div>
  );
};
