import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";

export const useViewmodel = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return {
    user,
    t,
  };
};
