import { useTranslation } from "react-i18next";
import { toPercent } from "utils/format";
import { cn } from "utils/styles";

type Props = {
  variant?: "primary" | "secondary";
  insideArea: number;
  insideAreaPercent: number;
  outsideArea: number;
  outisdeAreaPercent: number;
  pending: number;
  pendingPercent: number;
};

export const LeadsCard = ({
  variant = "primary",
  insideArea,
  insideAreaPercent,
  outisdeAreaPercent,
  outsideArea,
  pending,
  pendingPercent,
}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={cn(
        "rounded-2xl flex flex-1 flex-col",
        variant === "primary"
          ? "bg-primaryMultiply/70 pt-7 pb-5 px-7"
          : "border-white/30 border py-2 px-4 flex flex-col justify-center"
      )}
    >
      <div
        className={cn(
          "flex items-center justify-center tablet:justify-start",
          variant === "primary" ? "gap-2.5" : "gap-1"
        )}
      >
        <img
          className={cn(variant === "primary" ? "" : "h-3.5")}
          src="/icons/campaigns/leads.svg"
          alt=""
        />
        <p className={cn(variant === "primary" ? "" : "text-xs")}>
          {t("dashboard.cards.generatedLeads")}
        </p>
      </div>
      <div
        className={cn(
          "flex flex-col items-center tablet:flex-row justify-between font-input",
          variant === "primary" ? "mt-2 desktop:mt-8 gap-4" : "mt-2 gap-3"
        )}
      >
        <div>
          <div
            className={cn(
              "flex justify-center items-end font-bold",
              variant === "primary" ? "gap-2" : "gap-1"
            )}
          >
            <p
              className={cn(
                "text-center",
                variant === "primary"
                  ? "text-xl desktop-lg:text-2xl"
                  : "text-lg"
              )}
            >
              {insideArea}
            </p>
            <p className="text-xs mb-1">
              {toPercent(insideAreaPercent, i18n.language)}
            </p>
          </div>
          <p className="text-secondary text-xs">
            {t("dashboard.cards.insideArea")}
          </p>
        </div>
        <div>
          <div
            className={cn(
              "flex justify-center items-end font-bold",
              variant === "primary" ? "gap-2" : "gap-1"
            )}
          >
            <p
              className={cn(
                "text-center",
                variant === "primary"
                  ? "text-xl desktop-lg:text-2xl"
                  : "text-lg"
              )}
            >
              {outsideArea}
            </p>
            <p className="text-xs mb-1">
              {toPercent(outisdeAreaPercent, i18n.language)}
            </p>
          </div>
          <p className="text-secondary text-xs">
            {t("dashboard.cards.outsideArea")}
          </p>
        </div>
        <div>
          <div
            className={cn(
              "flex justify-center items-end font-bold",
              variant === "primary" ? "gap-2" : "gap-1"
            )}
          >
            <p
              className={cn(
                "text-center",
                variant === "primary"
                  ? "text-xl desktop-lg:text-2xl"
                  : "text-lg"
              )}
            >
              {pending}
            </p>
            <p className="text-xs mb-1">
              {toPercent(pendingPercent, i18n.language)}
            </p>
          </div>
          <p className="text-secondary text-xs">
            {t("dashboard.cards.pending")}
          </p>
        </div>
      </div>
    </div>
  );
};
