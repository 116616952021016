import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormStates } from "components/Forms/FormStates";

import { getProvider, updateProvider } from "services/providerService";

import { ProviderForm } from "../form";
import { FormFields } from "../form/viewModel";

import { queryClient } from "services/queryClient";
import { PROVIDER_CLIENTS_QUERY_KEY } from "../viewModel";
import { PageHeader } from "components/Page/PageHeader";
import { PROVIDER_SELECT_QUERY } from "components/Page/PageFilter/ProviderFilter";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { PageFilter } from "components/Page/PageFilter";
import { Button } from "components/Button";
import { useQueryParams } from "hooks/useQueryParams";

type PathParams = {
  id: string;
};

type QueryParams = {
  page?: string;
  filter?: string;
};

export function EditClient() {
  const { id } = useParams<PathParams>();
  const { page, filter } = useQueryParams<QueryParams>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading, isError, refetch } = useQuery(
    [PROVIDER_CLIENTS_QUERY_KEY, "form", id],
    () => getProvider(id!),
    { enabled: !!id }
  );

  async function handleSubmit(fields: FormFields) {
    toast(t("providers.edit.update_loading"), { type: "info" });
    await updateProvider({
      id: id!,
      leadsStatus: data!.leadsStatus,
      ...fields,
    });
    queryClient.invalidateQueries(PROVIDER_CLIENTS_QUERY_KEY);
    queryClient.invalidateQueries(PROVIDER_SELECT_QUERY);
    toast(t("providers.edit.update_success"), { type: "success" });
    navigate(`/clientes/${id!}?page=${page}&filter=${filter}`);
  }

  return (
    <section>
      <div className="flex justify-between items-center">
        <PageHeader title={t("providers.edit.title")} backPath={`/clientes?page=${page}&filter=${filter}`} />
        <div className="flex items-center gap-3">
          <PageFilter hideAllFilters />
          <Link to={`/clientes/${id!}?page=${page}&filter=${filter}`}>
            <Button>{t("actions.view")}</Button>
          </Link>
        </div>
      </div>
      <FormStates isLoading={isLoading} hasError={isError} onRetry={refetch} />
      {data && (
        <ProviderForm
          enabled
          provider={data}
          onSubmit={handleSubmit}
          action={t("actions.edit")}
        />
      )}
    </section>
  );
}
