import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Sidebar } from "components/Partials/Sidebar";
import { DotLoading } from "components/DotLoading";

import useAuth, { SELLER_CLIENTS_QUERY_KEY } from "hooks/useAuth";
import { useRoutes } from "hooks/useRoutes";

import { Route } from "utils/routes";
import { cn } from "utils/styles";
import { useQuery } from "react-query";
import { getSellers } from "services/sellerService";
import { useDispatch } from "react-redux";
import { updateSelectedSeller, updateSellers } from "store/slices/auth";
import { toMs } from "utils/date";
import { useTranslation } from "react-i18next";
import { SELLER_STATUS } from "models/seller";

type ProtectedRouteProps = {
  element: JSX.Element;
  removePadding?: boolean;
  match?: string;
};

export default function ProtectedRoute({
  element,
  removePadding,
}: ProtectedRouteProps) {
  const { user, isLoading, logout, handleGetUser, selectedProvider } =
    useAuth();
  const { routes } = useRoutes(user, logout);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { data: sellers, isLoading: loadingSellers } = useQuery(
    [SELLER_CLIENTS_QUERY_KEY, selectedProvider.id],
    () => getSellers(1, 100, selectedProvider.id),
    {
      enabled:
        selectedProvider.id !== "admin" &&
        user !== undefined &&
        user.role !== "seller",
      refetchOnWindowFocus: true,
      refetchInterval: toMs({ minutes: 1 }),
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      retryDelay: toMs({ seconds: 5 }),
      select: (data) => ({
        ...data,
        data: data.data.filter(
          (seller) => seller.status === SELLER_STATUS.Ativo
        ),
      }),
    }
  );

  function checkPath(route: Route) {
    const routeStart = location.pathname.split("/")[0];
    return route.path.includes(`/${routeStart}`);
  }

  useEffect(() => {
    if (!user) {
      handleGetUser();
    }
  }, []);

  useEffect(() => {
    if (sellers) {
      dispatch(updateSellers(sellers.data));
      dispatch(updateSelectedSeller("all"));
    } else {
      dispatch(updateSellers(undefined));
      dispatch(updateSelectedSeller("all"));
    }
  }, [sellers, loadingSellers]);

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        navigate("/login", { replace: true });
      } else if (location.pathname !== "/404" && !routes.some(checkPath)) {
        navigate("/404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);

  return isLoading || !i18n.isInitialized ? (
    <div className="bg-main bg-center bg-cover h-screen w-screen flex flex-col items-center justify-center">
      <img
        src="/images/brand/logo.png"
        className="mb-8"
        alt="Login logo"
        width={200}
      />
      <DotLoading style="default" />
    </div>
  ) : (
    <main className="flex bg-internal bg-cover bg-center h-screen">
      <Sidebar />
      <section
        className={cn(
          "w-screen max-w-internalPageMobile tablet:max-w-internalPage overflow-y-auto",
          removePadding ? "" : "py-8 px-8 tablet:py-15 tablet:px-18"
        )}
      >
        {element}
      </section>
    </main>
  );
}
