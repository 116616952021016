import { Button } from "components/Button";
import { TextField } from "components/Forms/TextField";
import { useViewModel } from "./viewModel";
import { ChatIntegration } from "models/chatIntegration";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { CopyText } from "components/Forms/CopyText";
import { MultiSelect } from "components/Forms/MultiSelect";
import { Select } from "components/Forms/Select";
import { midiaSocialOrigins } from "models/lead";
import { Label } from "components/Forms/Label";

type Props = {
  provider: string;
  selectedIntegration: ChatIntegration | undefined;
  onClose: () => void;
  onSave: SaveOrUpdateChatIntegrationFunction;
};

export const OpaSuiteForm = ({
  provider,
  selectedIntegration,
  onClose,
  onSave,
}: Props) => {
  const viewModel = useViewModel(provider, selectedIntegration, onSave);

  if (viewModel.step === 0) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="flex flex-col gap-5"
      >
        <TextField
          label="Nome da integração"
          placeholder="Digite o nome da integração"
          error={viewModel.errors.name?.message}
          register={viewModel.register("name", {
            required: { value: true, message: "O nome é obrigatório" },
          })}
          variant="secondary"
        />
        <TextField
          label="Domínio Opa Suite"
          placeholder="Digite o seu domínio personalizado do Opa Suite"
          error={viewModel.errors.opaSuite?.host?.message}
          register={viewModel.register("opaSuite.host", {
            required: { value: true, message: "O domínio é obrigatório" },
          })}
          variant="secondary"
        />
        <TextField
          label="Token de acesso"
          placeholder="Digite o token de acesso"
          error={viewModel.errors.opaSuite?.token?.message}
          register={viewModel.register("opaSuite.token", {
            required: { value: true, message: "O token é obrigatório" },
          })}
          variant="secondary"
        />
        <div className="modalButtons">
          {viewModel.selectedIntegration ? (
            <div className="flex-1">
              <Button
                onClick={viewModel.handleShowWebhookStep}
                type="button"
                variant="outline"
              >
                Webhook
              </Button>
            </div>
          ) : null}
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.loadingForm} theme="highlight">
            Salvar
          </Button>
        </div>
      </form>
    );
  }

  if (viewModel.step === 2) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormThirdStep)}
        className="flex flex-col gap-5 overflow-y-auto max-h-[calc(100svh-384px)]"
      >
        {viewModel.selectedSellersOptions.map((seller, index) => (
          <div key={seller.id}>
            <Select
              label={`Associe ${seller.name}`}
              onChange={(value) => {
                // Check if the internal seller is already select on the list
                const relatedSeller = viewModel.selectedSellersRelated.find(
                  (relatedSeller) => relatedSeller.seller === value
                );

                // Get current external seller
                const seller = viewModel.selectedSellersOptions[index];

                // Check if the external seller is already select on the list
                const sellerOnTheList = viewModel.selectedSellersRelated.find(
                  (relatedSeller) => relatedSeller.externalSeller === seller.id
                );

                // If external seller is selected on the list, change the internal seller related to the new selection
                if (sellerOnTheList) {
                  viewModel.setValue("opaSuite.sellersRelated", [
                    ...viewModel.selectedSellersRelated.filter(
                      (relatedSeller) =>
                        relatedSeller.externalSeller !== seller.id
                    ),
                    {
                      seller: value!,
                      externalSeller: seller.id,
                    },
                  ]);
                  return;
                }

                // If the internal seller selected is on the list, change they to the current external seller
                if (relatedSeller) {
                  viewModel.setValue("opaSuite.sellersRelated", [
                    ...viewModel.selectedSellersRelated.filter(
                      (relatedSeller) => relatedSeller.seller !== value!
                    ),
                    {
                      seller: value!,
                      externalSeller: seller.id,
                    },
                  ]);
                  return;
                }

                // If the internal and external seller isn't selected push it
                viewModel.setValue("opaSuite.sellersRelated", [
                  ...viewModel.selectedSellersRelated,
                  {
                    seller: value!,
                    externalSeller: seller.id,
                  },
                ]);
              }}
              options={viewModel.internalSellers.map((internalSeller) => ({
                value: internalSeller.id,
                label: internalSeller.name,
              }))}
              selected={
                viewModel.selectedSellersRelated.find(
                  (sellerRelated) => sellerRelated.externalSeller === seller.id
                )?.seller
              }
              error={viewModel.errors.type?.message}
              variant="secondary"
            />
          </div>
        ))}
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button
            loading={viewModel.loadingForm}
            theme="highlight"
            className="px-7"
          >
            Salvar
          </Button>
        </div>
      </form>
    );
  }

  if (viewModel.step === 3) {
    return (
      <div className="flex flex-col gap-5">
        <CopyText
          text={`${import.meta.env.VITE_API_URL}/v1/webhooks/opa_suite/${viewModel.selectedIntegration?.id}`}
          label="URL do webhook"
          variant="secondary"
        />
        <CopyText
          text={viewModel.selectedIntegration?.opaSuite?.webhookToken ?? ""}
          label="Token do webhook"
          variant="secondary"
        />
        <div className="modalButtons">
          <Button onClick={viewModel.handleCancelWebhookStep} theme="cancel">
            Cancelar
          </Button>
          <Button
            loading={viewModel.verifyLoading}
            onClick={viewModel.handleVerifyWebhook}
            theme="highlight"
            className="px-7"
          >
            Verificar
          </Button>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
      className="flex flex-col gap-5"
    >
      <MultiSelect
        label="Selecione os vendedores"
        onChange={(value) => viewModel.setValue("opaSuite.sellers", value)}
        options={viewModel.sellers.map((seller) => ({
          value: seller.id,
          label: seller.name,
        }))}
        selected={viewModel.selectedSellers}
        error={viewModel.errors.opaSuite?.sellers?.message}
        variant="secondary"
      />
      <MultiSelect
        label="Filtrar apenas pelas tags"
        placeholder="(Opcional) Selecione as tags"
        onChange={(value) => viewModel.setValue("opaSuite.onlyFromTags", value)}
        options={viewModel.tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
        }))}
        selected={viewModel.selectedOnlyFromTags}
        error={viewModel.errors.opaSuite?.onlyFromTags?.message}
        variant="secondary"
      />
      {viewModel.selectedSocialMatching.length > 0 && <Label>Atribuir tags a origem social</Label>}
      {viewModel.selectedSocialMatching.map((socialMatching, index) => (
        <div key={socialMatching.socialOrigin} className="flex items-center gap-2 relative">
          <MultiSelect
            onChange={viewModel.handleUpdateSocialMatchingTags(index)}
            selected={socialMatching.tag}
            label="Tags"
            options={viewModel.tags.map((tag) => ({
              value: tag.id,
              label: tag.name,
            }))}
            variant="secondary"
            className="flex-1"
          />
          <Select
            label="Origem"
            variant="secondary"
            selected={socialMatching.socialOrigin as string}
            onChange={viewModel.handleUpdateSocialMatchingOrigin(index)}
            options={midiaSocialOrigins(viewModel.t)}
            className="flex-1"
          />
          <Button
            type="button"
            theme="default"
            onClick={viewModel.handleRemoveSocialMatching.bind(null, index)}
            className="absolute right-0 top-0 h-6 w-6 leading-8 "
          >
            x
          </Button>
        </div>
      ))}
      <Button onClick={viewModel.handleAppendSocialMatching} type="button">+ Atribuir tags a origem sociais</Button>
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button
          loading={viewModel.loadingForm}
          theme="highlight"
          className="px-7"
        >
          Salvar
        </Button>
      </div>
    </form>
  );
};
